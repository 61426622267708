import React from 'react';
import { Link } from 'react-router-dom';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Helmet} from "react-helmet";

import GetInTouch from './common/getInTouch';


class Main extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            post: null,
            isLoaded: false
        }
    }

    async componentDidMount() {
        await fetch('/projectData.json')
            .then((response) => {
                return response.json();
            }).then((data) => {
                this.setState({ post: data, isLoaded: true })
            })
    }

    render() {
            var name = "These projects have been done by Amanur Rahman";
            var desc = "I'm Amanur Passionate at web application development working with python, javascript, sql, no sql etc";
            var ogImage = "amanur-og.png";
            var url = window.location.href;
            // var url_orgin = document.location.origin;
            var url_orgin = "https://project.amanur.com";
        if(!this.state.isLoaded){
            return (
                <div className="loader-home">
                    <div className="loader-spin"></div>
                </div>
            )
        }
        return (
            <div className="app-index">
                {/* <HelmetProvider> */}
                <Helmet>
                    <title>{name}</title>
                    <meta property="og:title" content={name} />
                    <link rel="canonical" href={url} />
                    <meta property="og:type" content="website" />
                    <meta name="description" content={desc} />
                    <meta property="og:description" content={desc} />
                    <meta property="og:image" content={`${url_orgin}/image/${ogImage}`} />
                    <meta property="og:image:type" content={`${url_orgin}/image/${ogImage}`}/>
                    <meta property="og:site_name" content="Amanur Rahman" />
                    <meta property="og:url" content={url_orgin} />
                    <meta name="twitter:title" content={name} />
                    <meta name="twitter:description" content={desc} />
                    <meta name="twitter:card" content={`${url_orgin}/image/${ogImage}`} />
                    <meta name="twitter:image" content={`${url_orgin}/image/${ogImage}`} />
                    <meta name="twitter:site" content={url_orgin} />
                    <meta name="twitter:image:alt" content={ogImage} />
                    <meta property="fb:app_id" content="281847513232231"/>
                    <meta name="revisit-after" content="5 days" />
                </Helmet>
            {/* </HelmetProvider> */}
                <div className="container">
                    <div className="row">
                        {
                            this.state.post && this.state.post.project.map((block, key) => {
                                return <div key={key} className="col-sm-6 col-lg-4">
                                    <div className="item-grid">
                                        <div className="image">
                                            <img src={"/image/portfolio/"+ block.url + "/" + block.featuredImage} alt={block.url} className="img-responsive img-thumbnail" />
                                            <div className="hover-view">
                                                <Link className="img-hover-icon"
                                                    to={location =>
                                                        `${location.pathname}project/${block.url}`
                                                    }
                                                ><i className="fa fa-link" aria-hidden="true"></i></Link>
                                                <a className="img-hover-icon" href={`/image/portfolio/${block.url}/${block.featuredImage}`}>
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <h2 className="item-text">{block.name}</h2>
                                        {/* <a href="">Read more</a> */}
                                        <Link
                                            to={location =>
                                                `${location.pathname}project/${block.url}`
                                            }
                                        >See more...</Link>
                                    </div>
                                </div>
                            }
                            )

                        }

                        {/* <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div>
                        <div className="col-sm-6 col-lg-4">
                            <img src="//placehold.it/300x100" className="img-responsive img-thumbnail" />
                            <p>Some content here that is short</p>
                            <a href="">Read more</a>
                        </div> */}
                    </div>
                </div>
                <br></br>
                <GetInTouch />
            </div>
        );
    }
}
export default Main;
