import React from 'react';

function GetInTouch() {

    return (
        <div className="contact">
            <div className="container">
                <div className="row">
                    <div className="col text-center mt-4 mb-4">
                        <h3 className="have-project">Have a Project for Me?</h3>
                        <h3 className="get-in-touch">GET IN TOUCH!</h3>
                        <a className="contact-me-today" href="https://amanur.com#contact">Contact me today!</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetInTouch;
